const rgex = /^[0-9]{5}-?[0-9]{3}$/

export const cepMask = (value: string) => {
  const cepNumber = value.trim().replace(/\D/g, '')
  if (rgex.test(cepNumber)) {
    if (cepNumber.length > 1) {
      return cepNumber
        .replace(/^([\d]{5})-?([\d]{3})$/, '$1-$2')
    } else {
      return cepNumber
    }
  } else {
    return false
  }
}
