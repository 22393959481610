const rgex = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/

export const phoneMask = (value: string) => {
  const phoneNumber = value.trim().replace(/\D/g, '')
  if (rgex.test(phoneNumber)) {
    if (phoneNumber.length > 1) {
      return phoneNumber
        .replace(/^(\d{2})(\d)/g, '($1) $2')
        .replace(/(\d)(\d{4})$/, '$1-$2')
    } else {
      return phoneNumber
    }
  } else {
    return false
  }
}
