import React, { useState } from "react";
import {
  Card,
  Col,
  Row,
  Form,
  Input,
  InputNumber,
  Select,
  notification,
} from "antd";
import {
  IPrice,
  IVoucher,
  IConfirmation,
  IPackage,
} from "../../../@types/Accept";
import { findAddress } from "../../../services/Accept";
import { phoneMask } from "../../../util/phoneMask";
import { cepMask } from "../../../util/cepMask";
import { socialRevenueMask } from "../../../util/socialRevenueMask";
import { useTranslation } from "react-i18next";
import ResumeCardInfo from "../../../components/ResumeCardInfo";

const { Search } = Input;
const { Option } = Select;

interface Step2Props {
  prevStep: () => void;
  nextStep: () => void;
  setVoucher: any;
  totalValue: number;
  totalDiscount: number;
  price: IPrice;
  voucher: IVoucher;
  confirmation: IConfirmation;
  setConfirmation: any;
  current: number;
  packageOptions: any;
  packages: IPackage[];
  setTotalValue: React.Dispatch<React.SetStateAction<number>>;
  setTotalDiscount: React.Dispatch<React.SetStateAction<number>>;
}
const Step2: React.FC<Step2Props> = ({
  prevStep,
  nextStep,
  setVoucher,
  totalValue,
  setTotalValue,
  totalDiscount,
  setTotalDiscount,
  price,
  voucher,
  confirmation,
  setConfirmation,
  current,
  packageOptions,
  packages,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [cepStatus, setCepStatus] = useState<null | any>(undefined);
  const [cepHelp, setCepHelp] = useState<null | any>(undefined);
  const [telephoneStatus, setTelephoneStatus] = useState<null | any>(undefined);
  const [telephoneHelp, setTelephoneHelp] = useState<null | any>(undefined);
  const [socialRevenueStatus, setSocialRevenueStatus] = useState<null | any>(
    undefined
  );
  const [socialRevenueHelp, setSocialRevenueHelp] = useState<null | any>(
    undefined
  );
  const [cepLoading, setCepLoading] = useState(false);

  function changePhone(field_name: any, field_value: any) {
    const phoneNumber = phoneMask(field_value);
    if (phoneNumber) {
      setTelephoneStatus("success");
      setTelephoneHelp(undefined);
      setConfirmation({ ...confirmation, [field_name]: phoneNumber });
      form.setFieldsValue({ confirmation: { [field_name]: phoneNumber } });
    } else {
      setTelephoneStatus("error");
      setTelephoneHelp(t("invalid_phone"));
    }
  }

  function changeCep(ev: any) {
    const cepNumber = cepMask(ev.target.value);
    if (cepNumber) {
      setCepStatus("success");
      setCepHelp(undefined);
      setConfirmation({ ...confirmation, cep: cepNumber });
      form.setFieldsValue({ confirmation: { cep: cepNumber } });
    } else {
      setCepStatus("error");
      setCepHelp(t("invalid_zip_code"));
    }
  }

  function changeSocialRevenue(ev: any) {
    const socialRevenueNumber = socialRevenueMask(ev.target.value);
    if (socialRevenueNumber) {
      setSocialRevenueStatus("success");
      setSocialRevenueHelp(undefined);
      setConfirmation({ ...confirmation, social_revenue: socialRevenueNumber });
      form.setFieldsValue({
        confirmation: { social_revenue: socialRevenueNumber },
      });
    } else {
      setSocialRevenueStatus("error");
      setSocialRevenueHelp(t("invalid_social_revenue"));
    }
  }

  function onSearch(value: any) {
    setCepLoading(true);
    findAddress(value).then(({ data }) => {
      setCepLoading(false);
      if (data) {
        setCepStatus("success");
        setCepHelp(undefined);
        setConfirmation({
          ...confirmation,
          street: data.address,
          city: data.city,
          state: data.state,
          neighborhood: data.neighborhood,
        });
        form.setFieldsValue({
          confirmation: {
            street: data.address,
            city: data.city,
            state: data.state,
            neighborhood: data.neighborhood,
          },
        });
      } else {
        setCepStatus("error");
        setCepHelp(t("invalid_zip_code"));
      }
    });
  }

  async function beforeNext() {
    const validated = await form.validateFields();
    if (
      validated &&
      ["success", undefined].includes(telephoneStatus) &&
      ["success", undefined].includes(cepStatus)
    ) {
      nextStep();
    } else {
      notification.error({
        message: t("error"),
        description: t("fill_fields"),
      });
    }
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={beforeNext}
      initialValues={{ confirmation }}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={15} md={15}>
          <Row gutter={[16, 16]} style={{ marginBottom: "0px" }}>
            <Col xs={24} sm={24} md={24}>
              <Card
                title={t("confirm_your_data")}
                style={{ borderBottomWidth: 0 }}
              >
                <Row gutter={[16, 16]} style={{ marginBottom: "0px" }}>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Item
                      name={["confirmation", "organization_name"]}
                      label={t("company")}
                      rules={[
                        { required: true, message: t("company_required") },
                      ]}
                    >
                      <Input
                        onBlur={(e) =>
                          setConfirmation({
                            ...confirmation,
                            organization_name: e.target.value,
                          })
                        }
                        placeholder={t("enter_company")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Item
                      name={["confirmation", "social_name"]}
                      label={t("company_name")}
                      rules={[
                        { required: true, message: t("company_name_required") },
                      ]}
                    >
                      <Input
                        onBlur={(e) =>
                          setConfirmation({
                            ...confirmation,
                            social_name: e.target.value,
                          })
                        }
                        placeholder={t("enter_company_name")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginBottom: "0px" }}>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Item
                      help={socialRevenueHelp}
                      validateStatus={socialRevenueStatus}
                      name={["confirmation", "social_revenue"]}
                      label={t("social_revenue")}
                      rules={[
                        {
                          required: true,
                          message: t("social_revenue_required"),
                        },
                      ]}
                    >
                      <Input
                        onBlur={changeSocialRevenue}
                        maxLength={18}
                        placeholder={t("enter_social_revenue")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Item
                      help={telephoneHelp}
                      validateStatus={telephoneStatus}
                      name={["confirmation", "organization_telephone"]}
                      label={t("telephone")}
                      rules={[
                        { required: true, message: t("telephone_required") },
                      ]}
                    >
                      <Input
                        onBlur={(e) =>
                          changePhone("organization_telephone", e.target.value)
                        }
                        maxLength={15}
                        placeholder={t("enter_telephone")}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} style={{ marginBottom: "0px" }}>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      name={["confirmation", "observation"]}
                      label={t("observation")}
                      style={{ marginBottom: "0px" }}
                    >
                      <Input.TextArea
                        onBlur={(e) =>
                          setConfirmation({
                            ...confirmation,
                            observation: e.target.value,
                          })
                        }
                        placeholder={t("enter_observation")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              <Card title={t("address")} style={{ borderTopWidth: 0 }}>
                <Row gutter={[16, 16]} style={{ marginBottom: "0px" }}>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Item
                      help={cepHelp}
                      validateStatus={cepStatus}
                      name={["confirmation", "cep"]}
                      label={t("zip_code")}
                      rules={[
                        { required: true, message: t("zip_code_required") },
                      ]}
                    >
                      <Search
                        autoComplete="none"
                        disabled={cepLoading}
                        loading={cepLoading}
                        maxLength={9}
                        value={confirmation.cep}
                        placeholder={t("enter_zip_code")}
                        onSearch={onSearch}
                        enterButton
                        onBlur={changeCep}
                        onChange={(e) =>
                          setConfirmation({
                            ...confirmation,
                            cep: e.target.value,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={8} sm={8} md={8}>
                    <Form.Item
                      name={["confirmation", "city"]}
                      label={t("city")}
                      rules={[{ required: true, message: t("city_required") }]}
                    >
                      <Input
                        value={confirmation.city}
                        placeholder={t("enter_city")}
                        onBlur={(e) =>
                          setConfirmation({
                            ...confirmation,
                            city: e.target.value,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={4} sm={4} md={4}>
                    <Form.Item
                      name={["confirmation", "state"]}
                      label={t("state")}
                      rules={[{ required: true, message: t("state_required") }]}
                    >
                      <Select
                        value={confirmation.state}
                        showSearch
                        placeholder={t("select_state")}
                        optionFilterProp="children"
                        onChange={(e) =>
                          setConfirmation({ ...confirmation, state: e })
                        }
                        filterOption={true}
                      >
                        <Option value="AC">AC</Option>
                        <Option value="AL">AL</Option>
                        <Option value="AP">AP</Option>
                        <Option value="AM">AM</Option>
                        <Option value="BA">BA</Option>
                        <Option value="CE">CE</Option>
                        <Option value="ES">ES</Option>
                        <Option value="GO">GO</Option>
                        <Option value="MA">MA</Option>
                        <Option value="MT">MT</Option>
                        <Option value="MS">MS</Option>
                        <Option value="MG">MG</Option>
                        <Option value="PA">PA</Option>
                        <Option value="PB">PB</Option>
                        <Option value="PR">PR</Option>
                        <Option value="PE">PE</Option>
                        <Option value="PI">PI</Option>
                        <Option value="RJ">RJ</Option>
                        <Option value="RN">RN</Option>
                        <Option value="RS">RS</Option>
                        <Option value="RO">RO</Option>
                        <Option value="RR">RR</Option>
                        <Option value="SC">SC</Option>
                        <Option value="SP">SP</Option>
                        <Option value="SE">SE</Option>
                        <Option value="TO">TO</Option>
                        <Option value="DF">DF</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginBottom: "0px" }}>
                  <Col xs={12} sm={12} md={12}>
                    <Form.Item
                      name={["confirmation", "neighborhood"]}
                      label={t("neighborhood")}
                      rules={[
                        { required: true, message: t("neighborhood_required") },
                      ]}
                    >
                      <Input
                        value={confirmation.neighborhood}
                        placeholder={t("enter_neighborhood")}
                        onBlur={(e) =>
                          setConfirmation({
                            ...confirmation,
                            neighborhood: e.target.value,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={8} sm={8} md={8}>
                    <Form.Item
                      name={["confirmation", "street"]}
                      label={t("street")}
                      rules={[
                        { required: true, message: t("street_required") },
                      ]}
                    >
                      <Input
                        value={confirmation.street}
                        placeholder={t("enter_street")}
                        onBlur={(e) =>
                          setConfirmation({
                            ...confirmation,
                            street: e.target.value,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={4} sm={4} md={4}>
                    <Form.Item
                      name={["confirmation", "number"]}
                      label={t("number")}
                      rules={[
                        { required: true, message: t("number_required") },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        value={confirmation.number}
                        type={"number"}
                        placeholder={t("enter_number")}
                        onBlur={(e) =>
                          setConfirmation({
                            ...confirmation,
                            number: e.target.value,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} style={{ marginBottom: "0px" }}>
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item
                      name={["confirmation", "complement"]}
                      label={t("complement")}
                    >
                      <Input
                        value={confirmation.complement}
                        placeholder={t("enter_complement")}
                        onBlur={(e) =>
                          setConfirmation({
                            ...confirmation,
                            complement: e.target.value,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        <ResumeCardInfo
          setVoucher={setVoucher}
          totalValue={totalValue}
          setTotalValue={setTotalValue}
          totalDiscount={totalDiscount}
          setTotalDiscount={setTotalDiscount}
          price={price}
          voucher={voucher}
          confirmation={confirmation}
          backAction={true}
          prevStep={prevStep}
          submitText={t("confirm")}
          current={current}
          setConfirmation={setConfirmation}
          packageOptions={packageOptions}
          packages={packages}
        />
      </Row>
    </Form>
  );
};

export default Step2;
