import api from "./api";

export const getPrice = async (voucher: string) => {
  return api.get(`/accepts/get_prices.json${voucher}`);
};

export const findAddress = async (cep: string) => {
  return api.get(`/accepts/find_address.json?cep=${cep}`);
};

export const findVoucher = async (voucher: string) => {
  return api.get(`/accepts/get_voucher.json?voucher=${voucher}`);
};

export const createConfirmation = async (confirmation: any, token: any) => {
  return api.post(`/accepts/create_confirmation`, {
    confirmation: confirmation,
    token: token,
  });
};

export const getPackages = async () => {
  return api.get(`/accepts/get_packages.json`);
};
