import React from 'react';
import './assets/styles/global.less';
import Routes from './routes';
import './I18n'
import { ConfigProvider } from 'antd';


import locale from 'antd/lib/locale/pt_BR';


function App() {
  return (
    <ConfigProvider locale={locale}>
      <Routes />
    </ConfigProvider>
  );
}

export default App;
