import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Accept from '../pages/Accept'

function AcceptRoutes() {
  return (
    <Switch>
      <Route
        path="/"
        render={() => (
          <Accept />
        )}
      />
    </Switch>
  )
}

const Routes: React.FC = () => {
  return (
    <BrowserRouter basename="/">
      <AcceptRoutes />
    </BrowserRouter>
  )
}

export default Routes
