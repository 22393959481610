import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEN from './en/translation.json'
import translationPT from './pt_BR/translation.json'

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  pt_BR: {
    translation: translationPT
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: navigator.language,
  fallbackLng: 'pt_BR',
  interpolation: {
    escapeValue: false
  }
})
export default i18n
