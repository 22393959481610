const rgex = /^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$/

export const socialRevenueMask = (value: string) => {
  const socialRevenueNumber = value.trim().replace(/\D/g, '')
  if (rgex.test(socialRevenueNumber)) {
    const newSocialRevenueNumber = socialRevenueNumber.replace(/[^\w\s]/gi, '')
    if (newSocialRevenueNumber.length === 11) {
      return newSocialRevenueNumber
        .replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
    } else if (newSocialRevenueNumber.length === 14) {
      return newSocialRevenueNumber
        .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')
    } else {
      return false
    }
  } else {
    return false
  }
}
