import React from "react";

export const LaptopUser = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 -4 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 0C20.5304 0 21.0391 0.210714 21.4142 0.585786C21.7893 0.960859 22 1.46957 22 2V12C22 13.11 21.11 14 20 14H24V16H0V14H4C3.46957 14 2.96086 13.7893 2.58579 13.4142C2.21071 13.0391 2 12.5304 2 12V2C2 0.89 2.89 0 4 0H20ZM20 2H4V12H20V2ZM12 8C14.21 8 16 8.9 16 10V11H8V10C8 8.9 9.79 8 12 8ZM12 3C12.5304 3 13.0391 3.21071 13.4142 3.58579C13.7893 3.96086 14 4.46957 14 5C14 5.53043 13.7893 6.03914 13.4142 6.41421C13.0391 6.78929 12.5304 7 12 7C10.89 7 10 6.11 10 5C10 3.89 10.9 3 12 3Z" />
  </svg>
);
