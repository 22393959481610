import React from "react";
import { Modal, Row, Col } from "antd";
import days from "dayjs";
import { useTranslation } from "react-i18next";
import { IVoucher } from "../../@types/Accept";

interface VoucherDetailsModalProps {
  visible: boolean;
  handleOk: () => void;
  voucher: IVoucher;
}
const VoucherDetailsModal: React.FC<VoucherDetailsModalProps> = ({
  visible,
  handleOk,
  voucher,
}) => {
  const { t } = useTranslation();

  const LicenseDiscount = () => {
    if (
      voucher.tiflux_license_discount &&
      voucher.tiflux_license_discount > 0
    ) {
      return (
        <Row>
          <Col xs={24} sm={24} md={24}>
            <p>
              <strong>
                {t("discount")} {t("tiflux_users")}:
              </strong>{" "}
              {voucher.tiflux_license_discount}%
            </p>
          </Col>
        </Row>
      );
    } else {
      return <></>;
    }
  };

  const SplashtopDiscount = () => {
    if (voucher.splashtop_discount && voucher.splashtop_discount > 0) {
      return (
        <Row>
          <Col xs={24} sm={24} md={24}>
            <p>
              <strong>
                {t("discount")} {t("splashtop")}:
              </strong>{" "}
              {voucher.splashtop_discount}%
            </p>
          </Col>
        </Row>
      );
    } else {
      return <></>;
    }
  };

  const UniqueDiscount = () => {
    const one_time_pack = voucher?.pack_discounts?.filter((p) => p.one_time);
    return (
      <>
        {one_time_pack && one_time_pack.length > 0 ? (
          one_time_pack.map((p, index) => (
            <Row key={index}>
              <Col xs={24} sm={24} md={24}>
                <p>
                  <strong>Desconto em Cobrança Única:</strong>{" "}
                  {p.package_item_name || ""} - {p.discount}%
                </p>
              </Col>
            </Row>
          ))
        ) : (
          <></>
        )}
      </>
    );
  };

  const WhatsAppDiscount = () => {
    if (voucher.whatsapp_discount && voucher.whatsapp_discount > 0) {
      return (
        <Row>
          <Col xs={24} sm={24} md={24}>
            <p>
              <strong>
                {t("discount")} {t("whatsapp")}:
              </strong>{" "}
              {voucher.whatsapp_discount}%
            </p>
          </Col>
        </Row>
      );
    } else {
      return <></>;
    }
  };

  const PlatformChatDiscount = () => {
    if (voucher.platform_chat_discount && voucher.platform_chat_discount > 0) {
      return (
        <Row>
          <Col xs={24} sm={24} md={24}>
            <p>
              <strong>
                {t("discount")} {t("platform_chat")}:
              </strong>{" "}
              {voucher.platform_chat_discount}%
            </p>
          </Col>
        </Row>
      );
    } else {
      return <></>;
    }
  };

  const P2PDiscount = () => {
    if (
      voucher.ti_peer_to_peer_discount &&
      voucher.ti_peer_to_peer_discount > 0
    ) {
      return (
        <Row>
          <Col xs={24} sm={24} md={24}>
            <p>
              <strong>
                {t("discount")} {t("remote_access_ti_peer_to_peer")}:
              </strong>{" "}
              {voucher.ti_peer_to_peer_discount}%
            </p>
          </Col>
        </Row>
      );
    } else {
      return <></>;
    }
  };

  const PackageDiscount = () => {
    return (
      <>
        {voucher.package_item_discount && voucher.package_item_discount > 0 ? (
          <Row>
            <Col xs={24} sm={24} md={24}>
              <p>
                <strong>Desconto em Pacote:</strong>{" "}
                {voucher.package_item_discount}%
              </p>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {voucher.pack_discounts && voucher.pack_discounts.length > 0 ? (
          <>
            {voucher.pack_discounts
              .filter((p) => !p.one_time)
              .map((disc, index) => (
                <Row key={index}>
                  <Col xs={24} sm={24} md={24}>
                    <p>
                      <strong>
                        Desconto em{" "}
                        {disc.type_packages === 0 ? "Pacote" : "Consultoria"}:{" "}
                      </strong>
                      {disc.package_item_name || ""} - {disc.discount}%
                    </p>
                  </Col>
                </Row>
              ))}
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <Row>
      <Col>
        {voucher && (
          <Modal
            title={t("voucher_details")}
            visible={visible}
            onOk={handleOk}
            onCancel={handleOk}
            cancelButtonProps={{ style: { display: "none" } }}
          >
            <Row>
              <Col xs={24} sm={24} md={24}>
                <p>
                  <strong>{t("name")}:</strong> {voucher.name}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24}>
                <p>
                  <strong>{t("description")}:</strong> {voucher.description}
                </p>
              </Col>
            </Row>
            <LicenseDiscount />
            <PlatformChatDiscount />
            <SplashtopDiscount />
            <WhatsAppDiscount />
            <P2PDiscount />
            <UniqueDiscount />
            <PackageDiscount />

            {voucher.discount_months && voucher.discount_months > 0 && (
              <Row>
                <Col xs={24} sm={24} md={24}>
                  <p>
                    <strong>{t("discount_period")}:</strong>{" "}
                    {voucher.discount_months} Meses
                  </p>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={24} sm={24} md={24}>
                <p>
                  <strong>{t("expiration_date")}:</strong>{" "}
                  {days(voucher.expiration_date).format(t("date_format"))}
                </p>
              </Col>
            </Row>
          </Modal>
        )}
      </Col>
    </Row>
  );
};

export default VoucherDetailsModal;
