import React from "react";
import { Card, Col, Row, Result, Button } from "antd";
import { useTranslation } from "react-i18next";

interface Step4Props {
  newAutoBuy: () => void;
}
const Step4: React.FC<Step4Props> = ({ newAutoBuy }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={24}>
        <Card>
          <Result
            status="success"
            title={t("successfully_completed")}
            subTitle={t("success_message")}
            extra={
              <Button type="primary" onClick={newAutoBuy}>
                {t("new_order")}
              </Button>
            }
          />
          {/* quando clicar em fazer novo pedido manter o passo Meus dados o resto zerar */}
          {/*  ter parametro ja e cliente nesse caso */}
        </Card>
      </Col>
    </Row>
  );
};

export default Step4;
